import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import NavButtons from 'components/Advanced_User/INSTAR_MQTT_Broker/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Debugging your MQTT Network",
  "path": "/Advanced_User/INSTAR_MQTT_Broker/Windows_macOS_and_LINUX_Apps/",
  "dateChanged": "2019-08-20",
  "author": "Mike Polinowski",
  "excerpt": "MQTT (Message Queuing Telemetry Transport) is a machine-to-machine (M2M)/ Internet of Things (IoT) connectivity protocol. It was designed as an extremely lightweight publish/subscribe messaging transport. It is useful for connections with remote locations where a small code footprint is required and/or network bandwidth is at a premium. All Full HD cameras now offer an internal MQTT broker: IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9010 Full HD, IN-9020 Full HD.",
  "image": "../AU_SearchThumb_MQTT.png",
  "social": "/images/Search/AU_SearchThumb_MQTT.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_MQTT_white.webp",
  "chapter": "Advanced User",
  "category": "smarthome",
  "type": "Windows"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='Debugging your MQTT Network' dateChanged='2019-08-20' author='Mike Polinowski' tag='INSTAR IP Camera' description='MQTT (Message Queuing Telemetry Transport) is a machine-to-machine (M2M)/ Internet of Things (IoT) connectivity protocol. It was designed as an extremely lightweight publish/subscribe messaging transport. It is useful for connections with remote locations where a small code footprint is required and/or network bandwidth is at a premium. All Full HD cameras now offer an internal MQTT broker: IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9010 Full HD, IN-9020 Full HD.' image='/images/Search/AU_SearchThumb_MQTT.png' twitter='/images/Search/AU_SearchThumb_MQTT.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/INSTAR_MQTT_Broker/Windows_macOS_und_LINUX_Apps/' locationFR='/fr/Advanced_User/INSTAR_MQTT_Broker/Windows_macOS_and_LINUX_Apps/' crumbLabel="MQTT" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "debugging-your-mqtt-network",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#debugging-your-mqtt-network",
        "aria-label": "debugging your mqtt network permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Debugging your MQTT Network`}</h1>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#debugging-mqtt-networks"
        }}>{`Debugging MQTT Networks`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#windows-and-macos-software"
            }}>{`Windows and macOS Software`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#mqtt-explorer"
        }}>{`MQTT Explorer`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#connect-to-your-cameras-broker"
            }}>{`Connect to your Cameras Broker`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#controlling-your-camera"
            }}>{`Controlling your Camera`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#mqttfx"
        }}>{`MQTT.fx`}</a></li>
    </ul>
    {/* /TOC */}
    <h2 {...{
      "id": "debugging-mqtt-networks",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#debugging-mqtt-networks",
        "aria-label": "debugging mqtt networks permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Debugging MQTT Networks`}</h2>
    <h3 {...{
      "id": "windows-and-macos-software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#windows-and-macos-software",
        "aria-label": "windows and macos software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Windows and macOS Software`}</h3>
    <p>{`There are plenty of free tools available that you can use to test the INSTAR MQTT server and to keep an eye on all messages that are processed by it. This is extremely useful when connecting new clients. In the following we are going to take a look at two of these tools that are both available for Windows, macOS and Linux:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://mqtt-explorer.com"
        }}>{`MQTT Explorer by Thomas Nordquist`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://mqttfx.jensd.de"
        }}>{`MQTT.fx`}</a></li>
    </ul>
    <p>{`If you want to use your console under Linux to subscribe/publish to MQTT topic instead - we have a `}<a parentName="p" {...{
        "href": "/en/Software/Linux/Node-RED/#installing-mosquitto"
      }}>{`guide for that here`}</a>{`.`}</p>
    <h2 {...{
      "id": "mqtt-explorer",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#mqtt-explorer",
        "aria-label": "mqtt explorer permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`MQTT Explorer`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "868px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/51f35afb774b01adbfb0fa701964f793/748b0/MQTT_Explorer_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "62.60869565217391%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACOklEQVQoz2WSzWoTURTH5y1UmgTaZpL7fc+9dz4yH3cmk0yMY2sLTdIqXSTUBxCp2E0FlW5dSEFw5RMUpbpz67OIW8EH0BlEq/1xOGf14xzu/zrWpp4xjDHeIIT40znn7BpSSgAwxsRx7Cgv0IPUGKO19hsAwPd93SCEYJQyxqnUVCqpvUGae0HoeV4URY4QXFJ0dYf4HykYgV5bum3htpXbEsjFmMznM4fE087+WWzzUTHM8+FoNA7D0BjjNdQDBBQ73eOP9OQTnFyuP/1sj57HvtqbzR3mp7g6GgyiLLNJEltrlVJSQr1QCKWNBlDxCB+cwuEz7/C0/+BFtLP0tdybzRxKWR9Rl5p1DBtEI6ZuTyZlWY5Ho2I4BJD16zVni25zdq8tcK+P8Hw2czBXLRqvkQEKxh066HHfljuDcje/u19MtmRUUn/I/JxGU5ZUPBxyMFJpjPFisXAwh7W+9/DJ2at3l/2w2sB1FHGaSWUIpQI0aA+UBmVko9VBASCElstlLbeQv7s6fvTybVePN6meTsqqqsqyzPPcZtZam2X1TNMkTVObZUVRMMZqGVF5q6dvdNVN12shz+W+8SNfgxKUkT4nfU4x/xchBEJotVo5EpQdTqQJiVBEaCYUTe6QpOLJlKcVTbd4WIhG+Ju7lL/le9vb7y8u5ot9hDCAkKA2Tr90Xn+HN9/Y+dfW+Y/Nxx8kdkUd3jU5CIL7BwdBEGBMKK3/IlEhMQkxcVMJUQGlhF6BMea67i/5J0a2rZ+X3HJwAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/51f35afb774b01adbfb0fa701964f793/e4706/MQTT_Explorer_01.avif 230w", "/en/static/51f35afb774b01adbfb0fa701964f793/d1af7/MQTT_Explorer_01.avif 460w", "/en/static/51f35afb774b01adbfb0fa701964f793/67bb0/MQTT_Explorer_01.avif 868w"],
              "sizes": "(max-width: 868px) 100vw, 868px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/51f35afb774b01adbfb0fa701964f793/a0b58/MQTT_Explorer_01.webp 230w", "/en/static/51f35afb774b01adbfb0fa701964f793/bc10c/MQTT_Explorer_01.webp 460w", "/en/static/51f35afb774b01adbfb0fa701964f793/1ae05/MQTT_Explorer_01.webp 868w"],
              "sizes": "(max-width: 868px) 100vw, 868px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/51f35afb774b01adbfb0fa701964f793/81c8e/MQTT_Explorer_01.png 230w", "/en/static/51f35afb774b01adbfb0fa701964f793/08a84/MQTT_Explorer_01.png 460w", "/en/static/51f35afb774b01adbfb0fa701964f793/748b0/MQTT_Explorer_01.png 868w"],
              "sizes": "(max-width: 868px) 100vw, 868px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/51f35afb774b01adbfb0fa701964f793/748b0/MQTT_Explorer_01.png",
              "alt": "INSTAR MQTT Broker und der MQTT Explorer",
              "title": "INSTAR MQTT Broker und der MQTT Explorer",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Once you `}<a parentName="p" {...{
        "href": "http://mqtt-explorer.com"
      }}>{`downloaded`}</a>{`, installed and started the app click on the `}<strong parentName="p">{`Plus`}</strong>{` icon in the top left to add your MQTT broker - in our case, our INSTAR Full HD camera.`}</p>
    <h3 {...{
      "id": "connect-to-your-cameras-broker",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#connect-to-your-cameras-broker",
        "aria-label": "connect to your cameras broker permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Connect to your Cameras Broker`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "869px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0adb0bc981e1e4d7b1b9c9f0ce978f1f/2b2c6/MQTT_Explorer_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.65217391304347%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAA7CAAAOwgEVKEqAAAAC2UlEQVQ4y3WRTU8bVxSG5z8E21A8NhgHYUt8SdDEGM/MnbHnw2bGGWzHliMhhFJaWQipUrJok+AFbJNFpKgqq6y678+pKvUPIHaxWT7VvcbIqOri0TtzP95z3nO1v/7+h5ubG25vb/n2bcR4fMf47o7ReKwYP+LufxgzGo0UWj3+lSD+hajzjubLD8S9Cxqt97T6Q0XcGxJ3L3gheXmv3QuizuSsvCM5aL/Hf/EOLZXeZ17fZyFb4bt7kul90suGYnHJUGvpZZPsU4ds3lZkViaqrwjSOcHismAha6HNzSd4kkown0qQSiVIJudIpeaUTr4TJBNPWFhIsbqaJ5/PsbKy/EB+hqf5HFp5MY3QdZJpHT2ts7S0RDabRc9kyGQy6LpOJpMll8tRKBRmKFIoFif6QAHtWF/nzWqJ7x2LZzu7lEolDg4OaLVahGFIHMf4vo/rug9aq9WwHQfbtrFtB8cWOI7AsgSaW7bohy3CZkTg+9TrdWUotdFocHh4iOd5yqRarRJFkdo3TVMZCmFhigBLeJimgebVfX4c/MTp6Sntdptms0m321UqO7QsC8dxEEIow36/r/Zlp3LPdWs04z5h1MGyDLSN9XUMw1Ds7u6yt7enoklkZ9JoEs1WhnJt2vF0L/DlOCYFNDnIzc1NNjY2WL837/V66nIQBCqyjD7tchZpJmdcrdaUmTIsFotsb2+ztbWlTCuViooqI0mmM5vOcBZZRBaTHct/WUR1KM1mDaXJNJKsKlVedoTA9zxlUqtWFULYCtf11JlHhtPIMuasmVTTMCjbNk6jQSxfPmzgxxGeX8My9wnDgCDw/hu5XC5zdXXF9fU1l5eXDIdDfvvyhd+/fuVT7xUnOzvE5Rp/ll7zx2pM3T0nevWZRucjhuigra2tPXoU+conJyecnZ1xfHzM0dERg8GAwfk5p/UGrefP8So2b5+F/Lzt4og2dvADjveaihnwL5oNOkJawZNkAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0adb0bc981e1e4d7b1b9c9f0ce978f1f/e4706/MQTT_Explorer_02.avif 230w", "/en/static/0adb0bc981e1e4d7b1b9c9f0ce978f1f/d1af7/MQTT_Explorer_02.avif 460w", "/en/static/0adb0bc981e1e4d7b1b9c9f0ce978f1f/53e78/MQTT_Explorer_02.avif 869w"],
              "sizes": "(max-width: 869px) 100vw, 869px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0adb0bc981e1e4d7b1b9c9f0ce978f1f/a0b58/MQTT_Explorer_02.webp 230w", "/en/static/0adb0bc981e1e4d7b1b9c9f0ce978f1f/bc10c/MQTT_Explorer_02.webp 460w", "/en/static/0adb0bc981e1e4d7b1b9c9f0ce978f1f/c3694/MQTT_Explorer_02.webp 869w"],
              "sizes": "(max-width: 869px) 100vw, 869px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0adb0bc981e1e4d7b1b9c9f0ce978f1f/81c8e/MQTT_Explorer_02.png 230w", "/en/static/0adb0bc981e1e4d7b1b9c9f0ce978f1f/08a84/MQTT_Explorer_02.png 460w", "/en/static/0adb0bc981e1e4d7b1b9c9f0ce978f1f/2b2c6/MQTT_Explorer_02.png 869w"],
              "sizes": "(max-width: 869px) 100vw, 869px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0adb0bc981e1e4d7b1b9c9f0ce978f1f/2b2c6/MQTT_Explorer_02.png",
              "alt": "INSTAR MQTT Broker und der MQTT Explorer",
              "title": "INSTAR MQTT Broker und der MQTT Explorer",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Type in your cameras local IP address as `}<strong parentName="p">{`Host`}</strong>{`, e.g. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`192.168.2.165`}</code>{`, with the `}<strong parentName="p">{`Port`}</strong>{` `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`8883`}</code>{` (with `}<strong parentName="p">{`Encryption (tls)`}</strong>{` enabled) or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`1883`}</code>{`  (`}<em parentName="p">{`without encryption`}</em>{`). The last line is your MQTT broker login that you have set in your cameras WebUI:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3eea98372041398236fcd61acedf3af2/36c33/MQTT_Explorer_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "64.78260869565217%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACgklEQVQoz02S208TQRjF9x8xoqAUbwlRMDwbTTRyEdoihNuLhOgLpd2dqYYWpSUSX/wTTAhRJALyAKZgkBB8MCC0VGK6u73gJcaoFHd3pjvdmTG7BeTkZPI9zMmc75cRAIAAgIAo9Y286BxdaIvOeyO2W6P/7Y3Ot43M9z5d6hkad3tbXa7K8srKmro6AUAIAJQAGIslXyeMiQ9/Jtf2Jtf37HNt7+VavuRX6/mZOH4W2/b5/B6v5/r5C+7ay4IIoF8EEMJsRuGc0ILGOTvwvpyJIcLUTNp/v/+er2/wZEXIdVYAThgAmJKVokV13dB0QzeQRalFqW4ghDEuFAyErGJRTsvddzs8XU2t9VfaW24IAQkGJAAgVBSFEKJpmmmalmVxzimlu7u7hmEghAxDp5TKaqrjzm13V3NDZ2NLt1vwi0CUYCnMGMMYE0JKbSmlGGPmiFLKOd/5sgNCoi/YHxgc8D8YsHe2w8AOm6apaVrp3lExmwD7ZbBkKjsg9gb6azt66m82NwgSsGtDGJRlmRCCMT7M2C8jVCTEXp8VOedbW8nqS+euXT3uqjrmOlMliBIUnbCqqpzz0raHkAuUU84LFv2ZLyCLf9yMnzpdUVZecaK87GJNtV3bARYsAdN1HSFkmqZd1TKLP5L8d2LzkxoYSw1P5WLvt9rbPI1NDS3uW909nTYwvwREAI4CsxFxzsy/LD3LlYnv2cScjN9lcULOPRl9HLU1EolEBFECogSCwWAmk3HYsINfwQ3C38p0Ls2V5Dc6M83exL4mkw+j0VAoFHa0TxtCuLKyksvlFEVRHWXS6vZneXZpY3o5vry4ujH+PD45tbqw+Gh4OBwODzn6B2INL/VF3QJUAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3eea98372041398236fcd61acedf3af2/e4706/MQTT_Explorer_03.avif 230w", "/en/static/3eea98372041398236fcd61acedf3af2/d1af7/MQTT_Explorer_03.avif 460w", "/en/static/3eea98372041398236fcd61acedf3af2/7f308/MQTT_Explorer_03.avif 920w", "/en/static/3eea98372041398236fcd61acedf3af2/c82bd/MQTT_Explorer_03.avif 946w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3eea98372041398236fcd61acedf3af2/a0b58/MQTT_Explorer_03.webp 230w", "/en/static/3eea98372041398236fcd61acedf3af2/bc10c/MQTT_Explorer_03.webp 460w", "/en/static/3eea98372041398236fcd61acedf3af2/966d8/MQTT_Explorer_03.webp 920w", "/en/static/3eea98372041398236fcd61acedf3af2/30833/MQTT_Explorer_03.webp 946w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3eea98372041398236fcd61acedf3af2/81c8e/MQTT_Explorer_03.png 230w", "/en/static/3eea98372041398236fcd61acedf3af2/08a84/MQTT_Explorer_03.png 460w", "/en/static/3eea98372041398236fcd61acedf3af2/c0255/MQTT_Explorer_03.png 920w", "/en/static/3eea98372041398236fcd61acedf3af2/36c33/MQTT_Explorer_03.png 946w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3eea98372041398236fcd61acedf3af2/c0255/MQTT_Explorer_03.png",
              "alt": "INSTAR MQTT Broker und der MQTT Explorer",
              "title": "INSTAR MQTT Broker und der MQTT Explorer",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`If you have chosen to use the `}<strong parentName="p">{`TLS Encryption`}</strong>{` you now have to click on `}<strong parentName="p">{`Advanced`}</strong>{` back inside the MQTT Explorer broker setup:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "876px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/602bc0afda98d5814bf73e777f3a600c/1b1d5/MQTT_Explorer_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "74.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACdUlEQVQoz3XRy27aQBQGYL9DCKSlhrENjeq7HS4Gewwe3wLG1BhHqCJCpO22+7aQvEAXkbrLKi+QbqKu8gJ9h6oXJet0l+IuKwYlRI366dfo10hHZ3GIr99+XF1eXV//WizSxSJN0z+rskjXubPqNze/0zT9/vOS2H3+rhvPwmTW35uHyTyIZ/GLo2h01N87vEuYzPvJvBfPotFhbzgLk3lnMO8M3hI5Ut8ijcdFIw+W7xZpkDQkKZgH64AyAmVUKFmgbJG0RTJWHrSop4jYymVyuUwum8lmM5vZjeW7uZHdXJYsLrlsplSiSyWKoQFDUwwDGAbQNNjeLhOPimS+WCyQBbJQpBmGoigAKABAEQCKoimKphmGZTmMX0Vgl5F4gdh/srMnQ91uVysV0zQHg8FwOIyiKI7jXayD+b5v34Iu0n27CQ0isXYn8Sgaxr2gFwRBp9PpdrthGEZR5Hmei3me5ziOhbWQNdbcN7WuX4dE0A9fvn51cHDQ7/fDMOxhCCHLsux7HMx1HOiij9vhF3p/KrYJVVFaWKVSMQzDdd0qVvufak2oqry2U2tqBMdxsiyLoijLsmmaYRiapqkoivSAjLMqO4ra0DSC53lVVRVFWQ0bhtFoNFY//+AURcBFkmVVVTUNb15NyrIMIZQkief5hztZUfRYrsVxzwRBFARRFOv1+npYkqR2u40QMk2z2Wzq90BdryH0odZ4r+mabaN223EcCOF6WBRF13Unk8l4PB6NRkmSeJjrukEQHB8ffzo7+3x+fnp6enJycnFxMZ1OCZZlJUkSRVEQBAghQsi27dWpzFutVsu2kYMvjs9m+76v6/pfNYT+F6ASUVkAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/602bc0afda98d5814bf73e777f3a600c/e4706/MQTT_Explorer_04.avif 230w", "/en/static/602bc0afda98d5814bf73e777f3a600c/d1af7/MQTT_Explorer_04.avif 460w", "/en/static/602bc0afda98d5814bf73e777f3a600c/5579a/MQTT_Explorer_04.avif 876w"],
              "sizes": "(max-width: 876px) 100vw, 876px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/602bc0afda98d5814bf73e777f3a600c/a0b58/MQTT_Explorer_04.webp 230w", "/en/static/602bc0afda98d5814bf73e777f3a600c/bc10c/MQTT_Explorer_04.webp 460w", "/en/static/602bc0afda98d5814bf73e777f3a600c/21dbd/MQTT_Explorer_04.webp 876w"],
              "sizes": "(max-width: 876px) 100vw, 876px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/602bc0afda98d5814bf73e777f3a600c/81c8e/MQTT_Explorer_04.png 230w", "/en/static/602bc0afda98d5814bf73e777f3a600c/08a84/MQTT_Explorer_04.png 460w", "/en/static/602bc0afda98d5814bf73e777f3a600c/1b1d5/MQTT_Explorer_04.png 876w"],
              "sizes": "(max-width: 876px) 100vw, 876px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/602bc0afda98d5814bf73e777f3a600c/1b1d5/MQTT_Explorer_04.png",
              "alt": "INSTAR MQTT Broker und der MQTT Explorer",
              "title": "INSTAR MQTT Broker und der MQTT Explorer",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`In the screenshot above you can see that we already have a wildcard (`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`#`}</code>{`) subscription by default. That means that once we connect to our MQTT Broker we will receive updates for every MQTT Topic that has been registered with the Broker.`}</p>
    <p>{`In the following window click on `}<strong parentName="p">{`Client Certificate`}</strong>{` and select t`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "876px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/35d6ccd5c67e08858ec36ad3129a0cca/1b1d5/MQTT_Explorer_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "74.78260869565217%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACQUlEQVQoz32Ry2saURTG54/wReLoPLSIjk9SUjOZOzrX66hTMmZ8VwKFagui0oFskzFdSBe6DGShjEghNKv+O6VQ2u6bjeBjV5xJialpfnyLc+69Hx/nXOzrt++/fv74fXu7XK6Wy9VqtTKLB1r9lfFisVjMZrP5fI69LJwdlc+Pq+dKrafUekdlrXzyoVS/KNTupVR7SrWXL2vF+kW+oh1XNbms5ZQzzI5zDpzbIcAuAXYI4MA5F8XjFO8k70V4U4Q35fYIxDOI04KLFpxkEqch5rBbHXar3ZTN4rBbbTaLzWYxW7PweCiPh6JpkqZJjyGaIgIBH+ZwOXfdLheO4y6coiiSNEUSBGG0JEVRfoPABn6/PxwOYwU8UmEO4wL/fG+PZdlMJiNJUiaTyeVyiUQCQpg1EEURbpBKpQAAmAxSr5VKvqBIOUmW5Waz2el0ut2uqqqtVuukXk8jJIoiQkjYAEK4NotSrvHubbPRkGW5VqsNBgNd1yeTyXg8/nxz0+v3D3ggbAEh5DgOi4TDvEEsFstms8PhUNf10Wj06fq6r2lf3N6Pe/uHEMIt8zrZHD0UCgWDwXQ63el0Tk9PVVV902hUS6X3L9hXHEjAR5LvzDGDYDAoSVK/37+6uppOp4qi7MfjcYS2Yx+Yo9FoJBIJhUIIoXa7rapqsVhECK23mhRgUhCeNkejUYZhJEm6vLzUdT2fzwMAHov8T3LY2FwymeR53rx+grt/9vl85rYYhmFZlud5AAD3D9sHALDswR9gjRZlcPru1QAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/35d6ccd5c67e08858ec36ad3129a0cca/e4706/MQTT_Explorer_05.avif 230w", "/en/static/35d6ccd5c67e08858ec36ad3129a0cca/d1af7/MQTT_Explorer_05.avif 460w", "/en/static/35d6ccd5c67e08858ec36ad3129a0cca/5579a/MQTT_Explorer_05.avif 876w"],
              "sizes": "(max-width: 876px) 100vw, 876px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/35d6ccd5c67e08858ec36ad3129a0cca/a0b58/MQTT_Explorer_05.webp 230w", "/en/static/35d6ccd5c67e08858ec36ad3129a0cca/bc10c/MQTT_Explorer_05.webp 460w", "/en/static/35d6ccd5c67e08858ec36ad3129a0cca/21dbd/MQTT_Explorer_05.webp 876w"],
              "sizes": "(max-width: 876px) 100vw, 876px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/35d6ccd5c67e08858ec36ad3129a0cca/81c8e/MQTT_Explorer_05.png 230w", "/en/static/35d6ccd5c67e08858ec36ad3129a0cca/08a84/MQTT_Explorer_05.png 460w", "/en/static/35d6ccd5c67e08858ec36ad3129a0cca/1b1d5/MQTT_Explorer_05.png 876w"],
              "sizes": "(max-width: 876px) 100vw, 876px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/35d6ccd5c67e08858ec36ad3129a0cca/1b1d5/MQTT_Explorer_05.png",
              "alt": "INSTAR MQTT Broker und der MQTT Explorer",
              "title": "INSTAR MQTT Broker und der MQTT Explorer",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Once you uploaded the certificate you are able to connect to your MQTT broker. Click on `}<strong parentName="p">{`Back`}</strong>{` twice and then `}<strong parentName="p">{`Connect`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "875px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9d41f20f630b156f5b026634cb939238/71c8e/MQTT_Explorer_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.91304347826087%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABxUlEQVQoz23Su66bMBgHcF4mlZqGS8yxgZBC7M/GoCRAMSCISE8VqT1z1SHrmTKcztk6pFLnJu/SF6ogPelF/U0evsvfljV/pBuWqev6ZDLBGBNCMMYIIfsZIcT9H0KI9vElmwMNg9D3/dVqtdlsqqrabrd1XSdJkqbpchBFEb8BxoXwfV/bPbzf7XZt21ZVlee5lPJaxwaU0uVy2XUd+4VynnDZcgDP87S6qpumWa/XYRgqpcqyVEoJITjnQggAKIpCKZWm6bU7yzKl+lCu62p3A4QQISSOYwBgjAkh2G3XM8ZYHxmALhaMsdlspk2nU8uyTNN0HKdt2yzLpJRN05RleZ11uyljLE3ToihgmP672TAMx3FuRX/2XPNzAD6EYsBf97ng381lWSZJEgQBpTQYhGEohCiKYhHBXDAqIKbBw3z2Jk//asYYV1XVdZ1SKs/zuu4fMs9zAJCRLEN5P49jGheQfbfoelV5M0+zbRsNbNve7/eXy+V4PB4Oh9PpdD6fPz89UcY4Zd/83Q/zwyf8Vt5/2bz7GmWPc9/VXgxGo9F4PMYYOwOMseu6/eGu/21TZNmvdDLWp4apG2hiINNClmX+BO/KshHAgT4IAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9d41f20f630b156f5b026634cb939238/e4706/MQTT_Explorer_06.avif 230w", "/en/static/9d41f20f630b156f5b026634cb939238/d1af7/MQTT_Explorer_06.avif 460w", "/en/static/9d41f20f630b156f5b026634cb939238/c963a/MQTT_Explorer_06.avif 875w"],
              "sizes": "(max-width: 875px) 100vw, 875px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9d41f20f630b156f5b026634cb939238/a0b58/MQTT_Explorer_06.webp 230w", "/en/static/9d41f20f630b156f5b026634cb939238/bc10c/MQTT_Explorer_06.webp 460w", "/en/static/9d41f20f630b156f5b026634cb939238/a1ca4/MQTT_Explorer_06.webp 875w"],
              "sizes": "(max-width: 875px) 100vw, 875px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9d41f20f630b156f5b026634cb939238/81c8e/MQTT_Explorer_06.png 230w", "/en/static/9d41f20f630b156f5b026634cb939238/08a84/MQTT_Explorer_06.png 460w", "/en/static/9d41f20f630b156f5b026634cb939238/71c8e/MQTT_Explorer_06.png 875w"],
              "sizes": "(max-width: 875px) 100vw, 875px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9d41f20f630b156f5b026634cb939238/71c8e/MQTT_Explorer_06.png",
              "alt": "INSTAR MQTT Broker und der MQTT Explorer",
              "title": "INSTAR MQTT Broker und der MQTT Explorer",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Once connected you are able to see all MQTT Topics that are registered with the server. The important once are `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`instar/all`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`instar/000389888811`}</code>{`, where the number `}<strong parentName="p">{`000389888811`}</strong>{` represents the MAC address of our camera and will be different on every camera you connect to.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "876px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/91b4b0c12088e88e719d189f1caf63d3/1b1d5/MQTT_Explorer_07a.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "89.99999999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAC5klEQVQ4y32RW2/jRBiG/VeQVntKE9vjxPV5xmN77PHM2I4Pm6S06SErVFiKSlNAFC1IHCTEv+K0LcsdXMAFAgSITVdCdP8BSrLdZhfBM6/mYqRH+r53pO++/+mHH3/5+dfZo9nF7Pxi9vjJo9nFPOdXmZ0/mT1eyfnFb7//9ceff0vNK58PXv1iMj2dTM92Ds9Gr3117+Tb/XceTqZnz7J3dDp+4+vJ9HT78MHO4YPq7pdvf/Lw+MNvJN58Wow+Q/RdCx9ZeLqO3vTIsRtN7eBpnOiYZPfj/P2k+ACn7/n0xCUnaXlSvfyRRJOwyNm6DtZ1zbb0bldeW7spd27LndY87ZaqtikNqypvmj7GLsZuEHgIOmU/k3TdGI93+v0KBwFCOIyIaVqKoqoqUFWgKGq314v9iGIivJAjkmBCMYlQUFaVpChK0zTD4VAIwRgrisIwDFmWlSWyDLoagT6DAfNwgPwA+aGPoesV/b5kmub+/v7W1lYQBHEch2F4ZS4AADiu60HPduZneRmmwTmXer3e5uZmFEXKYlZN0wAAL8iMsbqqI0KMABoh8oMAQVSWpdRqtfplOZnczbJcCME5hxC+IPOUiTxLQ3LfrT826xwRlok7TSO1223B+cZoEMch5ymldDnC5coyACBJEpqmEcQHOj0CyYAX493tjdFIWmvdzovB9uStVOySuLRta3VsWZZ1XTdMvS1fV7SbN9TrL7WvtTo3bt26lqZUkmU5L6qd3XtZsRERRmmCEOp0Os9kTdNoSoXg0BfuvC7LXgRCT9I0bW9vdzzezDJeFHld191ud7VwAIDrup7nOY7nrGDb9vyfEUIQQsuybNt2HOffbadpyhjzPBcuQAhhjBFCc7m9oHPJqnlVGKVJksRxTC55KqsLlP8AAJDn+WjBYHBnsKCua9/3JeV/WRaWJInIMs4Z433fDw8OXh8Oh47jSJ3nWc65+qIoynJP30cQho4LhRCEENOy/gHI3kPOW0a/rwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/91b4b0c12088e88e719d189f1caf63d3/e4706/MQTT_Explorer_07a.avif 230w", "/en/static/91b4b0c12088e88e719d189f1caf63d3/d1af7/MQTT_Explorer_07a.avif 460w", "/en/static/91b4b0c12088e88e719d189f1caf63d3/5579a/MQTT_Explorer_07a.avif 876w"],
              "sizes": "(max-width: 876px) 100vw, 876px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/91b4b0c12088e88e719d189f1caf63d3/a0b58/MQTT_Explorer_07a.webp 230w", "/en/static/91b4b0c12088e88e719d189f1caf63d3/bc10c/MQTT_Explorer_07a.webp 460w", "/en/static/91b4b0c12088e88e719d189f1caf63d3/21dbd/MQTT_Explorer_07a.webp 876w"],
              "sizes": "(max-width: 876px) 100vw, 876px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/91b4b0c12088e88e719d189f1caf63d3/81c8e/MQTT_Explorer_07a.png 230w", "/en/static/91b4b0c12088e88e719d189f1caf63d3/08a84/MQTT_Explorer_07a.png 460w", "/en/static/91b4b0c12088e88e719d189f1caf63d3/1b1d5/MQTT_Explorer_07a.png 876w"],
              "sizes": "(max-width: 876px) 100vw, 876px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/91b4b0c12088e88e719d189f1caf63d3/1b1d5/MQTT_Explorer_07a.png",
              "alt": "INSTAR MQTT Broker und der MQTT Explorer",
              "title": "INSTAR MQTT Broker und der MQTT Explorer",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You can find your cameras MAC address by opening the web user interface and going to `}<strong parentName="p">{`System`}</strong>{` / `}<strong parentName="p">{`Overview`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b561eb83838a8d64c9c7033591f5f3c6/f6f78/MQTT_Explorer_07b.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACJUlEQVQoz32RzWsTURTF519QiFgQFEQFi6v6UXBhF0XB0pgoViQLwUVNIR9tpLgI2C5MIhXcZiGmmCkB91rSZJJ04kdNbdGkkxRcJJiCk8Rk8jKTSV5m3syVSShBBH+8xeFyD+dwH/Vk0TO7/Nr2cuv+Cmv1bVr87O1A2uJjLT7W6h8Kqz996xl7N7A5G9ydeBg4PnLszPlRyuNZePU2+rFEkvtSYl9a/954960e41rxvBTNCTFOZApSNNfc2ENMQWIKrUxZp6Pb5qvjM6MXKIfLnUolAUDTNAAARYYuAl01NPQA+kO9Z7zDHe4HNzc+9tQ0Qjld7licAQDUEmu/6zxfEepCpVJVVEI0TSUa0fReT1VU0sU9udsFgC87mempiXvmScrhdMeZhGFGqFQsFYvFaH4j+zMLAIQQXdcxxu12G2OsqmoXG+bt3cz1O5PTNrORzCQMc0eWa7VaCyFJEjHGmqYbffsMhKIoGGMAyHK5Rwt2j3d+aG7L8q9qtSVJDYQG9Qbhf2PcIr/H2u3XZh7YKKdrnunXFjmu+H69wqYr6Q+1FKuUD4wLESIIAkKo0+loBgQAdr7GLo0dOXHyFOU4NHeazUb5oMXzUrUq8jwWxUFbWZYRQhhjXddV1UiOM4mjJtPZc6epOYc7mUoNv+q/DHY+b326fOXizakblOfx4vOVF2/C4dVQaDUUCofDdJ81ml77B5qmI5FIMBj0er1Ly0t/APk+BIOnBwyKAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b561eb83838a8d64c9c7033591f5f3c6/e4706/MQTT_Explorer_07b.avif 230w", "/en/static/b561eb83838a8d64c9c7033591f5f3c6/d1af7/MQTT_Explorer_07b.avif 460w", "/en/static/b561eb83838a8d64c9c7033591f5f3c6/7f308/MQTT_Explorer_07b.avif 920w", "/en/static/b561eb83838a8d64c9c7033591f5f3c6/a558a/MQTT_Explorer_07b.avif 932w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b561eb83838a8d64c9c7033591f5f3c6/a0b58/MQTT_Explorer_07b.webp 230w", "/en/static/b561eb83838a8d64c9c7033591f5f3c6/bc10c/MQTT_Explorer_07b.webp 460w", "/en/static/b561eb83838a8d64c9c7033591f5f3c6/966d8/MQTT_Explorer_07b.webp 920w", "/en/static/b561eb83838a8d64c9c7033591f5f3c6/3b46e/MQTT_Explorer_07b.webp 932w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b561eb83838a8d64c9c7033591f5f3c6/81c8e/MQTT_Explorer_07b.png 230w", "/en/static/b561eb83838a8d64c9c7033591f5f3c6/08a84/MQTT_Explorer_07b.png 460w", "/en/static/b561eb83838a8d64c9c7033591f5f3c6/c0255/MQTT_Explorer_07b.png 920w", "/en/static/b561eb83838a8d64c9c7033591f5f3c6/f6f78/MQTT_Explorer_07b.png 932w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b561eb83838a8d64c9c7033591f5f3c6/c0255/MQTT_Explorer_07b.png",
              "alt": "INSTAR MQTT Broker und der MQTT Explorer",
              "title": "INSTAR MQTT Broker und der MQTT Explorer",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`All topics under `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`instar/all`}</code>{` will affect all INSTAR Full HD cameras that are connected to your MQTT broker - if an topic is updated here all cameras will be switched accordingly.`}</p>
    <p>{`All topics under `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`instar/000389888811`}</code>{` only affect the camera with the MAC address corresponding with the number `}<strong parentName="p">{`000389888811`}</strong>{`. If you want to send a command to the camera that is running the active MQTT broker, you can either use the MAC address, or simply use the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`instar/local`}</code>{` prefix instead.`}</p>
    <h3 {...{
      "id": "controlling-your-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#controlling-your-camera",
        "aria-label": "controlling your camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Controlling your Camera`}</h3>
    <p>{`To control your camera select a topic you want to update:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/99d65cc96deec13ae6f8305d30339456/6bfd0/MQTT_Explorer_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60.434782608695656%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACHklEQVQoz2WQy27TQBiF/TSlSmJ7xpfUjC/j2GM748zYCWkKTpw2pWmDUKAqjcSGNStWPE+5KGqBJ0BigQRiDQUUt2GHaCCC8Onb/ItfR+cIb9+9//jh0+cvX2fFvCjmxcV8VswvLn67OC8vfyydFfPZ7PLb9+L8fCY0d1/cHE3z8Wk+Pm0PXw4OXw2PX2/fO1vYH5/1xqe0dxL3TuL8Ges/z0bTzeF0+3D68PEbgaZHvD0xnB2Ed6pWX0e9DSvXUG9p1czd8C5tHtv+gebvGfV9HI5wsB/yO4JXM3cH3Zj6jm3YVrVSXhMr1yTxH1lMbrQagW8fO/EjxELHTJsxjXxBVbXBYNBub9Zqnk+Ig7EsAwDgUkVRCSGeX3McK9f924rvWvbmVodzLkiSdCvrtlptz/PDMHRdV5Ik8AdZllVVtW2kbwBFldbl9TV5XZRKpdIaQoYgVsq00c76D6K4h5ApX+X+DYQwCAil3MF1x7aw7SxwXVcol8thGA1297a2MsMwRFGEEK48Y4wd7JgmspbYNsZYEEUxSZIsy5KEx3EMIVwJhwCmjLU4jwipE0J9EpMgIkEQhr86p2ma5zmllHOOMV5JVgAYkmCSNCdp84jxEUuOaNT1cC3wBVmWO51OlmWUUsYYxlhRlP86BylPWIPH9eQJsvbYfdZ9GtFcAADouq5coWnaYuGVtU0TVa8DrQorouyJJR0YFQUjy/0JhUHqgyZHeqkAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/99d65cc96deec13ae6f8305d30339456/e4706/MQTT_Explorer_08.avif 230w", "/en/static/99d65cc96deec13ae6f8305d30339456/d1af7/MQTT_Explorer_08.avif 460w", "/en/static/99d65cc96deec13ae6f8305d30339456/7f308/MQTT_Explorer_08.avif 920w", "/en/static/99d65cc96deec13ae6f8305d30339456/ecfe0/MQTT_Explorer_08.avif 1011w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/99d65cc96deec13ae6f8305d30339456/a0b58/MQTT_Explorer_08.webp 230w", "/en/static/99d65cc96deec13ae6f8305d30339456/bc10c/MQTT_Explorer_08.webp 460w", "/en/static/99d65cc96deec13ae6f8305d30339456/966d8/MQTT_Explorer_08.webp 920w", "/en/static/99d65cc96deec13ae6f8305d30339456/7ed23/MQTT_Explorer_08.webp 1011w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/99d65cc96deec13ae6f8305d30339456/81c8e/MQTT_Explorer_08.png 230w", "/en/static/99d65cc96deec13ae6f8305d30339456/08a84/MQTT_Explorer_08.png 460w", "/en/static/99d65cc96deec13ae6f8305d30339456/c0255/MQTT_Explorer_08.png 920w", "/en/static/99d65cc96deec13ae6f8305d30339456/6bfd0/MQTT_Explorer_08.png 1011w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/99d65cc96deec13ae6f8305d30339456/c0255/MQTT_Explorer_08.png",
              "alt": "INSTAR MQTT Broker und der MQTT Explorer",
              "title": "INSTAR MQTT Broker und der MQTT Explorer",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`In this case we choose the topic `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`alarm/area1/enable`}</code>{` to activate the motion detection area 1 on our camera. Note that the topics that are listed on the left are the `}<strong parentName="p">{`STATUS topics`}</strong>{` that display the current status on our camera. In our example the topic is `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`instar/000389888811/status/alarm/area1/enable`}</code>{` and it's current value is `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`{"val":"0"}`}</code>{`. To update this status topic and activate our detection area we have to send an update to the `}<strong parentName="p">{`COMMAND Topic`}</strong>{`. This is identical to the status topic minus the word `}<em parentName="p">{`status`}</em>{`: `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`instar/000389888811/alarm/area1/enable`}</code>{`. Copy this command into the topic field and add the payload `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`{"val":"1"}`}</code>{` and hit `}<strong parentName="p">{`PUBLISH`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e4f0e0f9d8d43ac9beaa7dcc2692d99a/18c13/MQTT_Explorer_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "61.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACFklEQVQoz2WRy27TQBSG/SDsKxRje8ZOajsej2ecseOxE19r59K0hVYsWFbdw9sUteEixIoFCyR6IQlFLFixY5H0LZBTqSrh13fO7j//OTrCx0+/Pn/5/fXbn6vF7dViNf9xO7v5h+vvq8v58mK2vJyv7riYLa8Xy5ufK8HN3/LR++7gvFud2fFpOHwT770LBtNuVeOXdTf4a6t3ZvBTk5/65ZQVUz48f37yQYjT3aI8QDjAJEQ40FqO1iIPaW1THu4UO/uen3W8mHkJ85JOJ2ZeKjjYPjo67Pd7GNu2bQEgK4r0kCeK1I14nCa045I1jkur0TBJYwFCuH9wkGWp67qe71PqyrKiKOAOSQEYwMSymKm7eouokKiQalpGaciYIMtSWY36SYUdjxDabrfltftuwGOgvNoSXzZFI1H8BCJfRL64zbYU+5GNNUEUGzzMJ09Ponhf1w1JkpQNAeB7LM7LblSyrucF3PFd5NodSoVGoxHyYLI7LorcMIz72HtBABBCtm2bptleY5ltq20hhARJktI0HQwGURT1+31VVTf8AIBgLYyx4ziEkLqoyxirzVmWTSYTznkURYQQCOFGeBiGeZ6naRrHMee8FwUecyh1BFVVx+NxURThWqZpAgA2zEEQlGWZ50WWFYeHz6rRi6Q89oO8fpWu65qmNZtNCOF/N8sQwnphghwHt7ZRVRbU37PYMXaLv8pi31gzHWBUAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e4f0e0f9d8d43ac9beaa7dcc2692d99a/e4706/MQTT_Explorer_09.avif 230w", "/en/static/e4f0e0f9d8d43ac9beaa7dcc2692d99a/d1af7/MQTT_Explorer_09.avif 460w", "/en/static/e4f0e0f9d8d43ac9beaa7dcc2692d99a/7f308/MQTT_Explorer_09.avif 920w", "/en/static/e4f0e0f9d8d43ac9beaa7dcc2692d99a/407b6/MQTT_Explorer_09.avif 1005w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e4f0e0f9d8d43ac9beaa7dcc2692d99a/a0b58/MQTT_Explorer_09.webp 230w", "/en/static/e4f0e0f9d8d43ac9beaa7dcc2692d99a/bc10c/MQTT_Explorer_09.webp 460w", "/en/static/e4f0e0f9d8d43ac9beaa7dcc2692d99a/966d8/MQTT_Explorer_09.webp 920w", "/en/static/e4f0e0f9d8d43ac9beaa7dcc2692d99a/49597/MQTT_Explorer_09.webp 1005w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e4f0e0f9d8d43ac9beaa7dcc2692d99a/81c8e/MQTT_Explorer_09.png 230w", "/en/static/e4f0e0f9d8d43ac9beaa7dcc2692d99a/08a84/MQTT_Explorer_09.png 460w", "/en/static/e4f0e0f9d8d43ac9beaa7dcc2692d99a/c0255/MQTT_Explorer_09.png 920w", "/en/static/e4f0e0f9d8d43ac9beaa7dcc2692d99a/18c13/MQTT_Explorer_09.png 1005w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e4f0e0f9d8d43ac9beaa7dcc2692d99a/c0255/MQTT_Explorer_09.png",
              "alt": "INSTAR MQTT Broker und der MQTT Explorer",
              "title": "INSTAR MQTT Broker und der MQTT Explorer",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You can see that the `}<strong parentName="p">{`STATUS topic`}</strong>{` was updated to the value 1. When you open your cameras webUI you will also find that the detection area 1 is now active! Congratulations, you have successfully taken control over your camera using the MQTT interface.`}</p>
    <h2 {...{
      "id": "mqttfx",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#mqttfx",
        "aria-label": "mqttfx permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`MQTT.fx`}</h2>
    <p>{`Once you `}<a parentName="p" {...{
        "href": "https://mqttfx.jensd.de"
      }}>{`downloaded`}</a>{`, installed and started the app click on the `}<strong parentName="p">{`Cog Wheel`}</strong>{` icon in the top center to add your MQTT broker - in our case, our INSTAR Full HD camera.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "876px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/03a3627a5395fdaba0a3ca44e64dfa8d/1b1d5/MQTT_fx_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "22.608695652173914%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAA3klEQVQY023OS27CMAAEUB+oEog4tImD7VBwfqh8SgB/Upq0KlKlLhAHYMEFYUeJBCYnqdwFEoK3GM1uBux229/94XRN61Olj+fjoTrr6h6tdVmWIM+Lxdfn/E0JIZRSUspMqdd0Eqb58GOVDMZJHMVxHNwIwxCkU/G++JHFNxeScy64EFk26/VHbErlEvkRbnkuQs17AH1mdL72i43XDjzkYowJpdh1yKNdrz1Aq2H9gxBC24YXptvAPGBd1u0EATOVmSSEOmxIXhR8QlbDaCLc6iQIuZ5Z8Ek0cnD7D8uXdfRDhmJlAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/03a3627a5395fdaba0a3ca44e64dfa8d/e4706/MQTT_fx_01.avif 230w", "/en/static/03a3627a5395fdaba0a3ca44e64dfa8d/d1af7/MQTT_fx_01.avif 460w", "/en/static/03a3627a5395fdaba0a3ca44e64dfa8d/5579a/MQTT_fx_01.avif 876w"],
              "sizes": "(max-width: 876px) 100vw, 876px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/03a3627a5395fdaba0a3ca44e64dfa8d/a0b58/MQTT_fx_01.webp 230w", "/en/static/03a3627a5395fdaba0a3ca44e64dfa8d/bc10c/MQTT_fx_01.webp 460w", "/en/static/03a3627a5395fdaba0a3ca44e64dfa8d/21dbd/MQTT_fx_01.webp 876w"],
              "sizes": "(max-width: 876px) 100vw, 876px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/03a3627a5395fdaba0a3ca44e64dfa8d/81c8e/MQTT_fx_01.png 230w", "/en/static/03a3627a5395fdaba0a3ca44e64dfa8d/08a84/MQTT_fx_01.png 460w", "/en/static/03a3627a5395fdaba0a3ca44e64dfa8d/1b1d5/MQTT_fx_01.png 876w"],
              "sizes": "(max-width: 876px) 100vw, 876px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/03a3627a5395fdaba0a3ca44e64dfa8d/1b1d5/MQTT_fx_01.png",
              "alt": "INSTAR MQTT Broker and MQTT.fx",
              "title": "INSTAR MQTT Broker and MQTT.fx",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Choose the `}<strong parentName="p">{`MQTT Broker`}</strong>{` as `}<strong parentName="p">{`Profile Type`}</strong>{` and type in your cameras local IP address as `}<strong parentName="p">{`Broker Address`}</strong>{`, e.g. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`192.168.2.165`}</code>{`, with the `}<strong parentName="p">{`Broker Port`}</strong>{` `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`8883`}</code>{` (with `}<strong parentName="p">{`Encryption (SSL/TLS)`}</strong>{` enabled) or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`1883`}</code>{`  (`}<em parentName="p">{`without encryption`}</em>{`).`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "871px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/74b45827aac4670a7c1f7cea8356e10c/9d5da/MQTT_fx_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "65.21739130434783%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB60lEQVQoz43O247SUBiG4d7MILSlXZsuYEp3q9sFDKG0paWyVWdiYoJHnHlgYjIXMyacMMYiG6/NDJjJoJj4nL//93Prx2+P6/XXh4fValUUxWaz+XFuu93udrv9fn84HH4e7XbbzbYoiu+cpmm2bVer1XK5XCqVBFEU+YpQLvGvrggChnYd9cPxeDwajbJsmCRJHMdvZrfjbNb2Oxyl1HEcQggAQBRFCOE1beksbnoh7Qz88LXjBb7vmaZZr9dlGUiy1G1FjsrqSOVM6riuB4AsCE+rQJaM5IN/d+/MP7ff37fuvphOy9A1z/MYY60jhKEMJYQRJ6M6xESSJFEUBUGAECGJl/krWSjrzXrg0tt3b5fL5XQ6DcMwiqJ+v6+qqoIVjBVOEASB559Gf8eANG1FcxWsKKRmUZrn+Xw+Hw6HaZpmWZYkCWOs3W4TQo7xC7JUpdlHd/bJskzf9ymlhmGoqmqapmVZ5Eg5whifxTzPQwgVDBGQGo2GbduGYU4mk8Vi4fs+AODUPLsUE4IwrtVqjLEgCKI4zvPcsiwIIT53KT6ehxAyxgZpGob9KIp0Xb8Q8y9UKpXn3xBCumG4rntz043jWNM0/Jd/xqceQuh5frfb7fV6uq4jhP43Pml3OoM0TZIkCII/ln8BOxbQfva+KU8AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/74b45827aac4670a7c1f7cea8356e10c/e4706/MQTT_fx_02.avif 230w", "/en/static/74b45827aac4670a7c1f7cea8356e10c/d1af7/MQTT_fx_02.avif 460w", "/en/static/74b45827aac4670a7c1f7cea8356e10c/ac357/MQTT_fx_02.avif 871w"],
              "sizes": "(max-width: 871px) 100vw, 871px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/74b45827aac4670a7c1f7cea8356e10c/a0b58/MQTT_fx_02.webp 230w", "/en/static/74b45827aac4670a7c1f7cea8356e10c/bc10c/MQTT_fx_02.webp 460w", "/en/static/74b45827aac4670a7c1f7cea8356e10c/81b74/MQTT_fx_02.webp 871w"],
              "sizes": "(max-width: 871px) 100vw, 871px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/74b45827aac4670a7c1f7cea8356e10c/81c8e/MQTT_fx_02.png 230w", "/en/static/74b45827aac4670a7c1f7cea8356e10c/08a84/MQTT_fx_02.png 460w", "/en/static/74b45827aac4670a7c1f7cea8356e10c/9d5da/MQTT_fx_02.png 871w"],
              "sizes": "(max-width: 871px) 100vw, 871px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/74b45827aac4670a7c1f7cea8356e10c/9d5da/MQTT_fx_02.png",
              "alt": "INSTAR MQTT Broker and MQTT.fx",
              "title": "INSTAR MQTT Broker and MQTT.fx",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Under `}<strong parentName="p">{`User Credentials`}</strong>{` type in your MQTT broker login that you have set in your cameras WebUI:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "871px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0fb2d72df1dfa5af63041199e04468d6/9d5da/MQTT_fx_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "16.086956521739133%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAIAAAAcOLh5AAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAeUlEQVQI12PQRgX6Bga2NtYOrp7O8TUeqc2O4bm2NjbW1jY2tjZWVlZGRkbGSIABWaeWlpahoaGJiYmxsbGRoYG5mamjg4O/v39kZGRERER0dLS3t7ehoSERmo2NzczNnZycwsLC0tLS0tPTs7Ozg4ODDQwMIAqAmgFk4CmL5y4MAQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0fb2d72df1dfa5af63041199e04468d6/e4706/MQTT_fx_03.avif 230w", "/en/static/0fb2d72df1dfa5af63041199e04468d6/d1af7/MQTT_fx_03.avif 460w", "/en/static/0fb2d72df1dfa5af63041199e04468d6/ac357/MQTT_fx_03.avif 871w"],
              "sizes": "(max-width: 871px) 100vw, 871px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0fb2d72df1dfa5af63041199e04468d6/a0b58/MQTT_fx_03.webp 230w", "/en/static/0fb2d72df1dfa5af63041199e04468d6/bc10c/MQTT_fx_03.webp 460w", "/en/static/0fb2d72df1dfa5af63041199e04468d6/81b74/MQTT_fx_03.webp 871w"],
              "sizes": "(max-width: 871px) 100vw, 871px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0fb2d72df1dfa5af63041199e04468d6/81c8e/MQTT_fx_03.png 230w", "/en/static/0fb2d72df1dfa5af63041199e04468d6/08a84/MQTT_fx_03.png 460w", "/en/static/0fb2d72df1dfa5af63041199e04468d6/9d5da/MQTT_fx_03.png 871w"],
              "sizes": "(max-width: 871px) 100vw, 871px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0fb2d72df1dfa5af63041199e04468d6/9d5da/MQTT_fx_03.png",
              "alt": "INSTAR MQTT Broker and MQTT.fx",
              "title": "INSTAR MQTT Broker and MQTT.fx",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`If you have chosen to use the `}<strong parentName="p">{`TLS Encryption`}</strong>{` via `}<strong parentName="p">{`Broker Port`}</strong>{` `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`8883`}</code>{` you now have to switch to the `}<strong parentName="p">{`SSL/TLS`}</strong>{` tab:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "873px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/828b1d05c2f01d84f069f0929dbd5116/35751/MQTT_fx_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "27.82608695652174%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAA3klEQVQY023OSXKDMBQEUM6TIGLpCyExfH0hUWKwEZQXvkG2uU6OmxTekOFVb7u6s/ykKIqyLL33iOh6753F5RE/Pun+3tUVogUAcQAAKWX2esIYAwCjta5UbcwY4xCn1k+laRC7McZ5nr33IQRjaiHE37JsbG/D1LpARJVSRcEYyzm/AIAqlTw89/9ZBqm4rEBptJYcXZclpbSu6+2wrreU0jwvbdtmLyd5ngNA0zSOyPd913VSSudcCMPx9pkwDAMRaa1/lBljQohpHPd937YtxoiIAPB2+Y1z/n37Cw+rT9050QQeAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/828b1d05c2f01d84f069f0929dbd5116/e4706/MQTT_fx_04.avif 230w", "/en/static/828b1d05c2f01d84f069f0929dbd5116/d1af7/MQTT_fx_04.avif 460w", "/en/static/828b1d05c2f01d84f069f0929dbd5116/7bc21/MQTT_fx_04.avif 873w"],
              "sizes": "(max-width: 873px) 100vw, 873px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/828b1d05c2f01d84f069f0929dbd5116/a0b58/MQTT_fx_04.webp 230w", "/en/static/828b1d05c2f01d84f069f0929dbd5116/bc10c/MQTT_fx_04.webp 460w", "/en/static/828b1d05c2f01d84f069f0929dbd5116/d21af/MQTT_fx_04.webp 873w"],
              "sizes": "(max-width: 873px) 100vw, 873px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/828b1d05c2f01d84f069f0929dbd5116/81c8e/MQTT_fx_04.png 230w", "/en/static/828b1d05c2f01d84f069f0929dbd5116/08a84/MQTT_fx_04.png 460w", "/en/static/828b1d05c2f01d84f069f0929dbd5116/35751/MQTT_fx_04.png 873w"],
              "sizes": "(max-width: 873px) 100vw, 873px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/828b1d05c2f01d84f069f0929dbd5116/35751/MQTT_fx_04.png",
              "alt": "INSTAR MQTT Broker and MQTT.fx",
              "title": "INSTAR MQTT Broker and MQTT.fx",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Enable SSL/TLS`}</strong>{` and click on `}<strong parentName="p">{`CA Certificate file`}</strong>{` and select the `}<strong parentName="p">{`INSTAR Trusted Client Certificate`}</strong>{` that you can download from your cameras WebUI:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3eea98372041398236fcd61acedf3af2/36c33/MQTT_Explorer_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "64.78260869565217%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACgklEQVQoz02S208TQRjF9x8xoqAUbwlRMDwbTTRyEdoihNuLhOgLpd2dqYYWpSUSX/wTTAhRJALyAKZgkBB8MCC0VGK6u73gJcaoFHd3pjvdmTG7BeTkZPI9zMmc75cRAIAAgIAo9Y286BxdaIvOeyO2W6P/7Y3Ot43M9z5d6hkad3tbXa7K8srKmro6AUAIAJQAGIslXyeMiQ9/Jtf2Jtf37HNt7+VavuRX6/mZOH4W2/b5/B6v5/r5C+7ay4IIoF8EEMJsRuGc0ILGOTvwvpyJIcLUTNp/v/+er2/wZEXIdVYAThgAmJKVokV13dB0QzeQRalFqW4ghDEuFAyErGJRTsvddzs8XU2t9VfaW24IAQkGJAAgVBSFEKJpmmmalmVxzimlu7u7hmEghAxDp5TKaqrjzm13V3NDZ2NLt1vwi0CUYCnMGMMYE0JKbSmlGGPmiFLKOd/5sgNCoi/YHxgc8D8YsHe2w8AOm6apaVrp3lExmwD7ZbBkKjsg9gb6azt66m82NwgSsGtDGJRlmRCCMT7M2C8jVCTEXp8VOedbW8nqS+euXT3uqjrmOlMliBIUnbCqqpzz0raHkAuUU84LFv2ZLyCLf9yMnzpdUVZecaK87GJNtV3bARYsAdN1HSFkmqZd1TKLP5L8d2LzkxoYSw1P5WLvt9rbPI1NDS3uW909nTYwvwREAI4CsxFxzsy/LD3LlYnv2cScjN9lcULOPRl9HLU1EolEBFECogSCwWAmk3HYsINfwQ3C38p0Ls2V5Dc6M83exL4mkw+j0VAoFHa0TxtCuLKyksvlFEVRHWXS6vZneXZpY3o5vry4ujH+PD45tbqw+Gh4OBwODzn6B2INL/VF3QJUAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3eea98372041398236fcd61acedf3af2/e4706/MQTT_Explorer_03.avif 230w", "/en/static/3eea98372041398236fcd61acedf3af2/d1af7/MQTT_Explorer_03.avif 460w", "/en/static/3eea98372041398236fcd61acedf3af2/7f308/MQTT_Explorer_03.avif 920w", "/en/static/3eea98372041398236fcd61acedf3af2/c82bd/MQTT_Explorer_03.avif 946w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3eea98372041398236fcd61acedf3af2/a0b58/MQTT_Explorer_03.webp 230w", "/en/static/3eea98372041398236fcd61acedf3af2/bc10c/MQTT_Explorer_03.webp 460w", "/en/static/3eea98372041398236fcd61acedf3af2/966d8/MQTT_Explorer_03.webp 920w", "/en/static/3eea98372041398236fcd61acedf3af2/30833/MQTT_Explorer_03.webp 946w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3eea98372041398236fcd61acedf3af2/81c8e/MQTT_Explorer_03.png 230w", "/en/static/3eea98372041398236fcd61acedf3af2/08a84/MQTT_Explorer_03.png 460w", "/en/static/3eea98372041398236fcd61acedf3af2/c0255/MQTT_Explorer_03.png 920w", "/en/static/3eea98372041398236fcd61acedf3af2/36c33/MQTT_Explorer_03.png 946w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3eea98372041398236fcd61acedf3af2/c0255/MQTT_Explorer_03.png",
              "alt": "INSTAR MQTT Broker und der MQTT Explorer",
              "title": "INSTAR MQTT Broker und der MQTT Explorer",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click on `}<strong parentName="p">{`Ok`}</strong>{` and back on the main Window select the broker you just set up and click on `}<strong parentName="p">{`Connect`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "875px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/cabb8748bf524afdc39311e30e70b50b/71c8e/MQTT_fx_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "67.3913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACaklEQVQoz23QXW/SUBjA8X4aNQodpe/tVg5tKS9jg8EwgQ2VxbnE6OISZDqNc4GtFBowlL6cViiw6YVzzuxiH8PPZNY5sxh/F8/FSf4nTx7ENm3Xdcejkeu68IblwmPb+aX3HAhvv19zHMeFTlvvI5S4xPM8S5MUSVA3cIrieakiZAQgplNJmqaxaBTHcTyYGBbl57l0TkAEQYin8sqWJj58DmILIB6XZRkAsCAIGMcSPM9wHMdyzBX6etIkTlMky3FIqVh8XNuobr6o1jbX19fW18pPHlXz+TwlyAxI790NJR6E7s/NkaxAMRxJ4PQ8kEtbvJTFcQJR0hkQi6WSCSUhy3IilVt9tr29//Ggsfv2zbt9o97YbzTqjd299x+arcNWq9U8PGpqzf5g8GqnjlAMQ+CEkkzF4/EFQWCU1dLTl9CDtmU5tm26bnAxdzabnQRms6kLDy9+fte0DhINbhCJRDAMi+LR0L072eUs9D4PDcOyTDtgWZbv+7PAZOJD2D4/P1VVFcEDRIAkSRRFlwoFx3MNY2CaphUwTXM0Gk0Dvu87sH3+41RV23/iv1+g4fDSyorjeYZh3I49z5tOp5PJZDweO456dvbt6Ej9T7xcKHjjsWkObduGENq27Thwdnz8NXDy5cT3e5eXF92ujtwur+PFXK7b62ntdqfb0TRN1/V+vzccDvu9nq7rnU7n4GBnMPhUr79GRFFMKArLsjRNS5LEslxqOb9WrZbL5UqlUiqVAAAMw7Isi2FYOBxG0Tmex7LZZK228e/aWCQC5MRKsbiYySiKIori1TooGglgV6KxGC1JAgDx31eKLpKQT1G7AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cabb8748bf524afdc39311e30e70b50b/e4706/MQTT_fx_05.avif 230w", "/en/static/cabb8748bf524afdc39311e30e70b50b/d1af7/MQTT_fx_05.avif 460w", "/en/static/cabb8748bf524afdc39311e30e70b50b/c963a/MQTT_fx_05.avif 875w"],
              "sizes": "(max-width: 875px) 100vw, 875px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/cabb8748bf524afdc39311e30e70b50b/a0b58/MQTT_fx_05.webp 230w", "/en/static/cabb8748bf524afdc39311e30e70b50b/bc10c/MQTT_fx_05.webp 460w", "/en/static/cabb8748bf524afdc39311e30e70b50b/a1ca4/MQTT_fx_05.webp 875w"],
              "sizes": "(max-width: 875px) 100vw, 875px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cabb8748bf524afdc39311e30e70b50b/81c8e/MQTT_fx_05.png 230w", "/en/static/cabb8748bf524afdc39311e30e70b50b/08a84/MQTT_fx_05.png 460w", "/en/static/cabb8748bf524afdc39311e30e70b50b/71c8e/MQTT_fx_05.png 875w"],
              "sizes": "(max-width: 875px) 100vw, 875px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/cabb8748bf524afdc39311e30e70b50b/71c8e/MQTT_fx_05.png",
              "alt": "INSTAR MQTT Broker and MQTT.fx",
              "title": "INSTAR MQTT Broker and MQTT.fx",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`By typing `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`#`}</code>{` into the input field and clicking on `}<strong parentName="p">{`Subscribe`}</strong>{` inside the `}<strong parentName="p">{`Subscribe`}</strong>{` tab you get a wildcard subscription for all MQTT topics that are registered on your MQTT broker.`}</p>
    <p>{`You can also use the `}<strong parentName="p">{`Publish`}</strong>{` tab to update a MQTT topic and switching the corresponding function on your camera.`}</p>
    <p>{`For example the topic `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`instar/000389888811/alarm/area1/enable`}</code>{`, where the number `}<strong parentName="p">{`000389888811`}</strong>{` represents the MAC address of our camera and will be different on every camera you connect to, can have the payload `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`{"val":"1"}`}</code>{` or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`{"val":"0"}`}</code>{` to either switch the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Alarm/Areas/"
      }}>{`alarm detection area 1`}</a>{` `}<strong parentName="p">{`on`}</strong>{` or `}<strong parentName="p">{`off`}</strong>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "873px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/71bb320fd564f7e7f408de76376136f6/35751/MQTT_fx_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "25.217391304347824%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABAUlEQVQY023Fy0rDQACF4byM4ioxc8sk6cyktcSFLppUbRAFq4guFCoEMbcJaXUl+g5S0kyoDymDGxd+/JxjrL/WnVLb7Xff90qp/pdSveqU7h9d17Vtu2k3BuShSx1CCPzDhhB4Qzw8ZkyE4zFEyLSsfU2vaZqe77IDYATnqTh7EJwxNuCcM86DIPA8j1IXAmATAiglGCNgQwAg0iDUjzEyxOHk5OImjqMoiuLpNI7jJEmS2ekoPHJmj3MSXO7s7lmWH07YKBz4HnVdIbhDKcKOcX93K6uiqiqp1XUtm6ZZrZZ5KV/ePj+ei/fF4ilNc/laVHWR51mWlWVZSXk1v/4BmgBvBSiDGcUAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/71bb320fd564f7e7f408de76376136f6/e4706/MQTT_fx_06.avif 230w", "/en/static/71bb320fd564f7e7f408de76376136f6/d1af7/MQTT_fx_06.avif 460w", "/en/static/71bb320fd564f7e7f408de76376136f6/7bc21/MQTT_fx_06.avif 873w"],
              "sizes": "(max-width: 873px) 100vw, 873px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/71bb320fd564f7e7f408de76376136f6/a0b58/MQTT_fx_06.webp 230w", "/en/static/71bb320fd564f7e7f408de76376136f6/bc10c/MQTT_fx_06.webp 460w", "/en/static/71bb320fd564f7e7f408de76376136f6/d21af/MQTT_fx_06.webp 873w"],
              "sizes": "(max-width: 873px) 100vw, 873px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/71bb320fd564f7e7f408de76376136f6/81c8e/MQTT_fx_06.png 230w", "/en/static/71bb320fd564f7e7f408de76376136f6/08a84/MQTT_fx_06.png 460w", "/en/static/71bb320fd564f7e7f408de76376136f6/35751/MQTT_fx_06.png 873w"],
              "sizes": "(max-width: 873px) 100vw, 873px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/71bb320fd564f7e7f408de76376136f6/35751/MQTT_fx_06.png",
              "alt": "INSTAR MQTT Broker and MQTT.fx",
              "title": "INSTAR MQTT Broker and MQTT.fx",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Switching back the `}<strong parentName="p">{`Subscribe`}</strong>{` tab shows you that your MQTT broker received your topic update for `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`instar/000389888811/alarm/area1/enable`}</code>{`, your camera received the command to switch on the alarm detection area on and in turn updated the topic `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`instar/000389888811/status/alarm/area1/enable`}</code>{` with the new status the camera is now in:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "872px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0c35418088f36aebf179693c570fadaf/65654/MQTT_fx_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "19.565217391304348%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAYAAACOXx+WAAAACXBIWXMAAA7CAAAOwgEVKEqAAAABE0lEQVQY002OT0vCcACG9310br+t3PyDzZjZHyJMbVpslEV06BB9iAiz7FKYRR2jIlIwXIWGQZ2ClD7QE+1Sh4fn5T28vJKu6/xH03XGhKC2s8vlUZ3mfpXz6gFXh3WuTxvcNJrcnl1w1/zjt+s/tKlVNpBUVUUIgaZpgcWvwyFO9o7pPH9y336j1Xmn9fgR8PQ64mXwTbf3hd8f0u0N8fsjfH9AdWsbybZtUqlU8M6yLBKJBMmJNN6Ky3rRYXPZpeKUcXN5CtOzFGfmmLcmsc04mViCqXiS9LjBaj7PWqmMZBgG0Wg0GDRNM8iGGcPxPFzPY6nksFjIk8lmCcky4UgEWVFQhEpEVVGEICSHWcjlAn4AUUOs00MzYlAAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0c35418088f36aebf179693c570fadaf/e4706/MQTT_fx_07.avif 230w", "/en/static/0c35418088f36aebf179693c570fadaf/d1af7/MQTT_fx_07.avif 460w", "/en/static/0c35418088f36aebf179693c570fadaf/749ce/MQTT_fx_07.avif 872w"],
              "sizes": "(max-width: 872px) 100vw, 872px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0c35418088f36aebf179693c570fadaf/a0b58/MQTT_fx_07.webp 230w", "/en/static/0c35418088f36aebf179693c570fadaf/bc10c/MQTT_fx_07.webp 460w", "/en/static/0c35418088f36aebf179693c570fadaf/a8a2c/MQTT_fx_07.webp 872w"],
              "sizes": "(max-width: 872px) 100vw, 872px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0c35418088f36aebf179693c570fadaf/81c8e/MQTT_fx_07.png 230w", "/en/static/0c35418088f36aebf179693c570fadaf/08a84/MQTT_fx_07.png 460w", "/en/static/0c35418088f36aebf179693c570fadaf/65654/MQTT_fx_07.png 872w"],
              "sizes": "(max-width: 872px) 100vw, 872px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0c35418088f36aebf179693c570fadaf/65654/MQTT_fx_07.png",
              "alt": "INSTAR MQTT Broker and MQTT.fx",
              "title": "INSTAR MQTT Broker and MQTT.fx",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      